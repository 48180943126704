const Spectrum = () => {
	return (
		<div>
			Atypical<br />
			black<br />
			child<br />
			doesn’t<br />
			engage<br />
			family.<br />
			Granted,<br />
			<br />
			autistic<br />
			boy<br />
			cannot<br />
			decipher<br />
			emotions<br />
			fully.<br />
			General<br />
			<br />
			associations/<br />
			behaviors<br />
			concerning<br />
			disorder:<br />
			echolalia,<br />
			flapping,<br />
			grinding teeth,<br />
			hyperactivity,<br />
			IQ tests,<br />
			jumping,<br />
			kicking,<br />
			laughing,<br />
			medication,<br />
			neurological exams,<br />
			OCD,<br />
			poor eye contact,<br />
			quirks,<br />
			retarded?<br />
			Spectrum, speech therapy, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming, stimming...
		</div>
	)
}

export default Spectrum